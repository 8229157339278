<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import autofit from "autofit.js";

export default {
  name: "App",

  mounted() {
    autofit.init({
      designHeight: 1080,
      designWidth: 1920,
      renderDom: "#app",
      resize: true,
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  overflow-y: scroll;
//   ::-webkit-scrollbar {
//     width: 10px;
//     height: 8px;
//     background-color: #f5f5f5;
//   }
//   /*定义滚动条轨道
//  内阴影+圆角*/
//   ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 10px;
//     background-color: #f5f5f5;
//   }
//   /*定义滑块
//  内阴影+圆角*/
//   ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: #c6c2c2;
//   }
}
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #f5f5f5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c6c2c2;
}
</style>
