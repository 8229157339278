const ComHeader = {
  home: "首页",
  female: "女士",
  male: "男士",
  unisex: "儿童",
  material: "面料",
  headerSearch: "请输入内容",
};

const ComFooter = {
  readyToWorkTogether: "准备好一起工作了吗 ?",
  privacyPolicy: "隐私政策",
  contactUs: "联系我们",
};

const home = {
  inquiryEntry: "我们的服务",
  inquiry: "获取报价",
  proofing: "获取样品",
  customized: "开始定制",
  iWantToInquire: "我要询价",
  iWantToProofing: "我要打样",
  iWantToCustomized: "我要定制",
  newStyleRecommended: "新款推荐",
  hotStyleRecommended: "爆款推荐",
};

const showRoom = {
  addEnquiryBasket: "加入询盘篮",
  loadMore: "加载更多",
  noMore: "暂无更多",
  materialNum: "面料号",
  styleNum: "款号",
  composition: "成分",
  enquiryBasket: "询盘篮",
  enquiryNow: "立即询盘",
  operate: "操作",
  moveOut: "移出询盘",
};

const details = {
  styleName: "款名",
  category: "分类",
  season: "季节",
  weaveClassification: "编织分类",
  ingredients: "成分",
  styleInfo: "样式信息",
  color: "颜色",
  size: "尺码",
  barCode: "条形码",
  englishName: "英文名称",
  tag: "标签",
  attribution: "适用品类",
  width: "门幅",
  weight: "重量",
  updated: "更新时间",
  stockAvailable: "库存情况",
  AISwap: "AI 换款",
};

const enquiry = {
  userInfo: "用户信息",
  productInfo: "产品信息",
  enterUserName: "请输入用户名",
  enterUserTel: "请输入电话",
  enterUserEmail: "请输入邮箱",
  enterUserAddress: "请输入详细地址",
  back: "返回",
  address: "地址",
  inguiryTypes: "询价方式",
  enquiry: "询盘",
  quantity: "数量",
  addSku: "添加属性行",
};

const inquiry = {
  inquirycategory: "询价品类",
  customerName: "客户名称",
  customerModelNumber: "客户款号",
  region: "地区",
  targetUnitPrice: "期望单价",
  inquirySize: "询价尺码",
  bulkyColor: "大货颜色",
  bulkySize: "大货尺码",
  quantityOfOrder: "下单件数",
  expectedDeliveryDate: "期望交期",
  typeOfOrder: "订单类型",
  inquiryMethod: "询价方式",
  remark: "备注",
  attachmentInfo: "附件资料",
  fabricRequirements: "面料要求",
  excipientRequirements: "辅料要求",
  fitRequirements: "版型要求",
  processRequirements: "工艺要求",
  packagingRequirements: "包装物流",
  submitAnRFQ: "提交询价单",
  uploadText: "将文件拖带此处，或",
  orClick: "点击上传",
  fullName: "全名",
  email: "邮箱",
  contactNum: "联系电话",
  companyName: "公司名称",
  message: "简讯",
  customerInfo: "客户信息",
  inquiryInfo: "询价信息",
  orderInfo: "订单信息",
  staging: "暂存",
};

export default {
  ...ComHeader,
  ...ComFooter,
  ...home,
  ...showRoom,
  ...details,
  ...enquiry,
  ...inquiry,
};
