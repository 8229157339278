import VueI18n from "vue-i18n";
import Vue from "vue";
import zh from "./modules/zh.js";
import en from "./modules/en.js";
import store from "@/store";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.i18n,
  messages: {
    zh: {
      ...zh,
    },
    en: {
      ...en,
    },
  },
});

export default i18n;
