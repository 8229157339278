const ComHeader = {
  home: "Home",
  female: "Female",
  male: "Male",
  unisex: "Unisex",
  material: "Material",
  headerSearch: "Please enter your content",
};

const ComFooter = {
  readyToWorkTogether: "Ready to work together ?",
  privacyPolicy: "Privacy Policy",
  contactUs: "Contact us",
};

const home = {
  inquiryEntry: "OUR SERVICES",
  inquiry: "GET A QUOTE",
  proofing: "REQUEST A SAMPLE",
  customized: "START CUSTOMIZING",
  iWantToInquire: "I want to inquire",
  iWantToProofing: "I want to proofing",
  iWantToCustomized: "I want to customized",
  newStyleRecommended: "NEW ARRIVALS",
  hotStyleRecommended: "BEST SELLERS",
};

const showRoom = {
  addEnquiryBasket: "Add enquire basket",
  loadMore: "Load more",
  noMore: "NO MORE",
  materialNum: "Fabric num",
  styleNum: "Style num",
  composition: "Composition",
  enquiryBasket: "Enquiry basket",
  enquiryNow: "Enquiry now",
  operate: "Operate",
  moveOut: "Move out",
};

const details = {
  styleName: "Style name",
  category: "Category",
  season: "Season",
  weaveClassification: "Weave Classification",
  ingredients: "Ingredients",
  styleInfo: "Style info",
  color: "Color",
  size: "Size",
  barCode: "Bar code",
  englishName: "English name",
  tag: "Tag",
  attribution: "Attribution",
  width: "Width",
  weight: "Weight",
  updated: "Updated",
  stockAvailable: "Stock available",
  AISwap: "AI Swap",
};

const enquiry = {
  userInfo: "User info",
  productInfo: "Product info",
  enterUserName: "Please enter your name",
  enterUserTel: "Please enter your tel",
  enterUserEmail: "Please enter your email",
  enterUserAddress: "Please enter your address details",
  back: "Back",
  address: "Address",
  enquiry: "Enquiry",
  inguiryTypes: "Type of Inguiry",
  quantity: "Quantity",
  addSku: "Add sku"
};

const inquiry = {
  inquirycategory: "Inquiry category",
  customerName: "Customer name",
  customerModelNumber: "Customer model number",
  region: "Region",
  targetUnitPrice: "Target unit price",
  inquirySize: "Inquiry size",
  bulkyColor: "Bulky Color",
  bulkySize: "Bulky Size",
  quantityOfOrder: "Quantity of order",
  expectedDeliveryDate: "Expected delivery date",
  typeOfOrder: "Type of order",
  inquiryMethod: "Quotation Method",
  remark: "Remark",
  attachmentInfo: "Attachment Information",
  fabricRequirements: "Fabric requirements",
  excipientRequirements: "Accessory requirements",
  fitRequirements: "Fit requirements",
  processRequirements: "Process requirements",
  packagingLogistics: "Packaging logistics",
  submitAnRFQ: "Submit an RFQ",
  uploadText: "Drag the file here or ",
  orClick: "click Upload",
  fullName: "Full Name",
  email: "Email",
  contactNum: "Contact Number",
  companyName: "Company Name",
  message: "Message",
  customerInfo: "Customer Information",
  inquiryInfo: "Inquiry Information",
  orderInfo: "Order Information",
  staging: "Staging",
};

export default {
  ...ComHeader,
  ...ComFooter,
  ...home,
  ...showRoom,
  ...details,
  ...enquiry,
  ...inquiry,
};
