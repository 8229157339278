<template>
  <div class="com_header_container">
    <div class="top_banner">
      <img src="@/assets/img/top_banner.jpg" alt="" />
    </div>
    <div class="home_nav">
      <div class="logo_box">
        <a href="/">
          <img
            src="@/assets/img/home_top_logo.png"
            loading="eager"
            alt="勤拓文字标识黑色"
            class="nav-logo"
          />
        </a>
      </div>
      <div class="menu_box">
        <el-menu
          :default-active="activeIndex"
          size="small"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="home">{{ $t("home") }}</el-menu-item>
          <el-menu-item index="female">{{ $t("female") }}</el-menu-item>
          <el-menu-item index="male">{{ $t("male") }}</el-menu-item>
          <el-menu-item index="unisex">{{ $t("unisex") }}</el-menu-item>
          <el-menu-item index="material">{{ $t("material") }}</el-menu-item>
        </el-menu>

        <div class="category_box">
          <div
            class="category_btn"
            @click="hidden"
            :style="{
              display,
            }"
          >
            <i class="el-icon-arrow-down" ref="icon"></i>
          </div>
          <div
            class="category_list"
            v-for="(category, index) in productCategory"
            :key="index"
            :style="{ height: height + 'px' }"
          >
            <div class="title">{{ category.label }}</div>
            <div class="item_box">
              <div
                class="item"
                v-for="item in category.children"
                :key="item.code"
                @click="sendCategoryFn(category, item)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btns">
        <el-button circle @click="translateLang">
          <i class="iconfont icon-fanyi translate"></i>
        </el-button>
      </div>
      <el-input
        :placeholder="$t('headerSearch')"
        suffix-icon="el-icon-search"
        v-model.lazy="input"
        @change="searchProductListFn"
      ></el-input>
      <div
        class="product_list"
        :style="{ height: productData.length > 0 ? '350px' : '0px' }"
      >
        <div
          class="item"
          v-for="item in productData"
          :key="item.id"
          @click="goProductDetails(item.id)"
        >
          <div class="img">
            <img :src="item.productImages[0]?.url" alt="" />
          </div>
          <div class="info">
            <p>
              <span>{{ item.productCode }}</span>
            </p>
            <p>
              <span>{{ item.component }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchProductList } from "@/http/api/showRoom.js";
import { getProductCategory } from "@/http/api/home.js";
export default {
  data() {
    return {
      input: "",
      activeIndex: "home",
      productData: [],
      productCategory: [],
      height: 0,
      display: "none",
    };
  },

  watch: {
    activeIndex() {
      this.height = 0;
      this.display =
        this.activeIndex !== "home" && this.activeIndex !== "material"
          ? "block"
          : "none";
      this.$refs.icon.style.transform = "rotate(0deg)";
    },
  },

  created() {
    if (this.$route.query.name || this.$route.name === "home") {
      this.activeIndex = this.$route.query.name || "home";
    } else {
      this.activeIndex = "";
    }

    this.getProductCategoryFn();
  },
  methods: {
    // 获取成衣列表
    async searchProductListFn() {
      let res = await searchProductList(
        JSON.stringify({
          page: 1,
          page_size: 99999,
          filter: {
            type: "LIKE",
            attribute: "productName",
            value: this.input,
          },
        })
      );
      if (this.input !== "") {
        this.productData = res.data;
      } else {
        this.productData = [];
      }
    },

    // 获取产品分类
    async getProductCategoryFn() {
      let res = await getProductCategory(
        JSON.stringify({
          lang: this.$store.state.i18n === "zh" ? "cn" : "en",
        })
      );
      this.productCategory = res.productCategories;
    },

    sendCategoryFn(category1, category2) {
      this.$emit("sendCategory", category1, category2);
      this.hidden();
    },

    hidden() {
      if (this.height === 0) {
        this.height = 500;
        this.$refs.icon.style.transform = "rotate(180deg)";
      } else {
        this.height = 0;
        this.$refs.icon.style.transform = "rotate(0deg)";
      }
    },

    // 前往产品详情
    goProductDetails(id) {
      this.$router.push({
        path: "/productDetails",
        query: {
          id,
        },
      });
    },

    translateLang() {
      this.$store.commit("TranslateLang");
    },

    handleSelect(key) {
      this.activeIndex = key;
      if (key === "home") {
        this.$router.push({
          path: "/",
        });
      } else {
        this.$router.push({
          path: "/showRoom",
          query: {
            name: key,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.com_header_container {
  position: sticky;
  top: -64px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #25293b;
  z-index: 9999;
  .top_banner {
    width: 100%;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .home_nav {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    padding: 0 60px;
    box-sizing: border-box;
    background-color: #fff;

    .logo_box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      img {
        width: auto;
        height: 60px;
      }
    }

    .menu_box {
      position: relative;
      width: 100%;

      .category_box {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: start;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        box-shadow: 5px 10px 20px #ccc;
        width: 600px;

        .category_btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 24px;
          background-color: #eee;
          font-size: 20px;
          cursor: pointer;

          &::after {
            content: "";
            display: block;
            position: absolute;
            right: -24px;
            top: 0;
            width: 0;
            height: 0;
            border-width: 12px 12px 12px 12px;
            border-style: solid;
            border-color: #eee transparent transparent #eee;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: -24px;
            top: 0;
            width: 0;
            height: 0;
            border-width: 12px 12px 12px 12px;
            border-style: solid;
            border-color: #eee #eee transparent transparent;
          }
          .el-icon-arrow-down {
            transform-origin: center;
            transition: all 0.3s ease-out;
          }
        }

        // overflow: hidden;

        .category_list {
          flex: 1;
          overflow: hidden;
          transition: all 0.2s ease-out;
          height: 500px;
          .title {
            font-size: 20px;
            font-weight: 700;
            height: 30px;
            margin-bottom: 20px;
            margin-top: 5px;
            padding: 20px 0;
            border-bottom: 1px solid #ccc;
          }

          .item_box {
            overflow-y: auto;
            max-height: 400px;
            margin-bottom: 30px;
            .item {
              height: 26px;
              margin-bottom: 10px;
              font-size: 16px;
              font-weight: 400;
              margin: 0 30px;
              margin-top: 10px;
              box-sizing: border-box;
              &:hover {
                border-bottom: 1px solid #ccc;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .el-menu-demo {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 60px;
      border-bottom: none;
      .el-menu-item {
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .el-input {
      max-width: 250px;
      height: 40px;
    }

    .product_list {
      position: absolute;
      top: 50px;
      right: 60px;
      width: 250px;
      max-height: 350px;
      height: 0;
      background-color: #f1f1f1;
      overflow-y: auto;
      padding: 0 10px;
      box-sizing: border-box;
      transition: all 0.3s;
      box-shadow: 5px 5px 20px;

      .item {
        display: flex;
        width: 100%;
        height: 100px;
        margin: 5px 0;
        background-color: #fff;
        box-shadow: 5px 5px 10px;
        cursor: pointer;

        .img {
          width: 80px;
          height: 100%;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          width: 140px;
          font-size: 14px;
          margin-top: 20px;
          span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 20px;
      .translate {
        font-size: 12px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: #f5f5f5;
  }
  /*定义滚动条轨道
 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  /*定义滑块
 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c6c2c2;
  }
}
</style>
