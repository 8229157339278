import serviceAxios from "../index";

// 获取成衣列表
export const getProductList = (data) => {
  return serviceAxios({
    url: "/entity/listingProduct",
    method: "get",
    data,
  });
};

// 获取首页三张图片
export const getMainImage = (data) => {
  return serviceAxios({
    url: "/action/scm.action.listingProduct.api.getMainImage?solution=solStdj8pD2peQp&token=pDANLKTnKxsVmBTqC51Axd",
    method: "post",
    data,
  });
};

// 获取产品分类
export const getProductCategory = (data) => {
  return serviceAxios({
    url: "/action/scm.action.listingProduct.api.getSettings?solution=solStdj8pD2peQp&token=skQcqaDg9p35a6j8uDSFvk",
    method: "post",
    data,
  });
};
