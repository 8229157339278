<template>
  <div class="com_footer_container">
    <el-row>
      <el-col :span="24">
        <div class="footer_main">
          <div class="footer_content">
            <div class="footer_grid">
              <a href="/" class="w-inline-block"
                ><img
                  src="https://assets-global.website-files.com/629f09acd23ab0f428f5963b/62ab63e8239ec476ea965578_kane-top-logo-full-white.svg"
                  alt="勤拓全标志白"
                  class="footer-logo"
              /></a>
              <h3 class="large_heading" style="opacity: 1">
                {{ $t("readyToWorkTogether") }}
              </h3>
              <div
                id="w-node-_0120e4c0-04c6-33ab-8815-2a5e43530f7c-43530f23"
                class="social-links"
              >
                <a
                  href=""
                  target="_blank"
                  class="wechat"
                  @click.prevent="display = true"
                  ><img
                    src="https://assets-global.website-files.com/629f09acd23ab0f428f5963b/62ab67040097b578b37c0671_wechat.svg"
                    alt="微信图标" /></a
                ><a
                  href="https://www.linkedin.com/company/kanetop/"
                  target="_blank"
                  class="social-link w-inline-block"
                  ><img
                    src="https://assets-global.website-files.com/629f09acd23ab0f428f5963b/62ab6728fa6d4e30594a6097_linkedin.svg"
                    alt=""
                /></a>
              </div>
              <a href="/" class="button"
                ><div class="btn-text font-white">{{ $t("contactUs") }}</div></a
              >
            </div>
            <a href="/privacy-policy" class="footer_text footer_link">{{
              $t("privacyPolicy")
            }}</a>
            <div class="footer_text">
              ©KANE TOP COMPANY LIMITED 2022<br />Website by&nbsp;<a
                href="https://www.acestudiohouse.com"
                target="_blank"
                class="footer_text footer_link footer_link2"
                ><span class="semi-bold">Ace Studio</span></a
              >
            </div>
          </div>
          <div class="footer-motif"></div>
        </div>
      </el-col>
    </el-row>
    <div class="wechat_overlay" v-if="display">
      <img
        src="https://assets-global.website-files.com/629f09acd23ab0f428f5963b/62eaae17b21df93d9d3f7a70_wechat%20kanetop%20(1).webp"
        loading="lazy"
        alt="微信二维码"
        class="wechat_qr"
      />
      <el-button class="exit_modal" @click="display = false"> X </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      display: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.com_footer_container {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 500px;
  padding: 80px 100px 16px;
  background-color: #000;
  box-sizing: border-box;
  overflow: hidden;

  .el-row {
    width: 100%;
    height: 100%;

    .footer_main {
      display: flex;
      width: 100%;

      .footer_content {
        width: 50%;
        text-align: left;

        .footer_grid {
          display: grid;
          grid-auto-columns: 1fr;
          grid-column-gap: 40px;
          grid-row-gap: 54px;
          grid-template-rows: auto auto;
          grid-template-columns: 1fr 1fr;
          align-items: end;
          margin-bottom: 96px;

          .large_heading {
            text-transform: uppercase;
            font-size: 32px;
            font-weight: 400;
            margin-bottom: 0;
            line-height: 1.3em;
            color: #fff;
          }

          .button {
            text-align: center;
            text-transform: uppercase;
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 200px;
            padding: 16px 44px;
            font-size: 14px;
            font-weight: 600;
            line-height: 100%;
            transition: background-color 0.2s;
            max-width: 100%;
            display: inline-block;
            color: #fff;
            text-decoration: none;
          }

          .wechat {
            margin-right: 20px;
          }
        }

        .footer_text {
          color: rgba(239, 239, 239, 0.5);
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 300;
          line-height: 130%;
        }

        .footer_link {
          color: #fff;
          margin-bottom: 16px;
          transition: color 0.3s;
          display: inline-block;
          text-decoration: none;
        }
        .footer_link2 {
          color: rgba(239, 239, 239, 0.5);
          font-weight: 600;
        }
      }
    }
  }

  .footer-motif {
    background-image: url("@/assets/img/logo.png");
    background-position: 100% 0;
    background-size: 140px;
    width: 100%;
    height: 150%;
    position: absolute;
    top: 9%;
    bottom: auto;
    left: auto;
    right: -40vw;
    transform: rotate(-32deg);
    opacity: 0.2;
  }

  .wechat_overlay {
    display: flex;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    transition: all 0.3;

    .exit_modal {
      width: 32px;
      position: absolute;
      top: 64px;
      bottom: auto;
      left: auto;
      right: 80px;
      cursor: pointer;
      opacity: 0.3;
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 60px;
    }
  }
}
</style>
