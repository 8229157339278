import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home/HomeView.vue";

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/showRoom",
    name: "showRoom",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ShowRoom/ShowRoom.vue"),
  },
  {
    path: "/detailsView",
    name: "detailsView",

    component: () => import("../views/DetailsView/DetailsView.vue"),
  },
  {
    path: "/productDetails",
    name: "productDetails",
    component: () => import("../views/ProductDetails/ProductDetails.vue"),
  },
  {
    path: "/enquiryView",
    name: "enquiryView",
    component: () => import("../views/EnquiryView/EnquiryView.vue"),
  },
  {
    path: "/inquiryView",
    name: "inquiryView",
    component: () => import("../views/InquiryView/InquiryView.vue"),
  },
  {
    path: "/aiSwap",
    name: "AISwap",
    component: () => import("../views/AISwap/AISwap.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
