<template>
  <div class="home_container">
    <ComHeader></ComHeader>
    <div class="home_main">
      <!-- 询价入口 -->
      <div class="home_classify_nav">
        <div class="classify_nav_main">
          <div class="classify_nav_left">
            <div class="title">{{ $t("inquiryEntry") }}</div>
            <div class="entrances">
              <div>
                <el-link :underline="false" @click="goInquiry('inquiry')">{{
                  $t("inquiry")
                }}</el-link>
              </div>
              <div>
                <el-link :underline="false">{{ $t("proofing") }}</el-link>
              </div>
              <div>
                <el-link :underline="false">{{ $t("customized") }}</el-link>
              </div>
            </div>
          </div>
          <div class="classify_nav_right">
            <p>
              <img :src="mainImages.Customized_main_image" alt="" />
            </p>
          </div>
        </div>
      </div>

      <!-- 新款推荐 -->
      <div class="home_new_recommend">
        <h1>{{ $t("newStyleRecommended") }}</h1>
        <div class="swiper-container" ref="mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="item in newProduct"
              :key="item.id"
              @click="goProductDetails(item.id)"
            >
              <img :src="item.productImages[0]?.url" :alt="item.productName" />
              <div class="new_recommend_info">
                <p>
                  <span>{{ $t("styleNum") }}：</span>
                  <span>{{ item.productCode }}</span>
                </p>
                <p>
                  <span>{{ item.productName }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>

      <!-- 爆款推荐 -->
      <div class="home_hot_recommend">
        <h1>{{ $t("hotStyleRecommended") }}</h1>
        <div class="hot_recommend_list">
          <div
            class="hot_recommend_item"
            v-for="item in hotProduct"
            :key="item.id"
            @click="goProductDetails(item.id)"
          >
            <img :src="item.productImages[0]?.url" alt="" />
            <div class="hot_recommend_info">
              <p>
                <span>{{ item.productName }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
// @ is an alias to /src
import ComHeader from "@/components/ComHeader.vue";
import ComFooter from "@/components/ComFooter.vue";
import { getProductList, getMainImage } from "@/http/api/home.js";

import swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "HomeView",
  components: {
    ComHeader,
    ComFooter,
  },
  data() {
    return {
      nowDate: "",
      mySwiper: {},
      newProduct: [],
      hotProduct: [],
      mainImages: {},
    };
  },
  created() {
    this.getMainImageFn();
    this.getProductListFn();
  },
  mounted() {
    // 轮播图
    this.swiperFn();
  },
  methods: {
    // 获取列表
    async getProductListFn() {
      let res = await getProductList();
      let data = res.data;
      data.forEach((item) => {
        if (item.HotSellingItems !== null || item.HotSellingItems !== "") {
          this.hotProduct.push(item);
        }
        if (item.NewModels !== null || item.NewModels !== "") {
          this.newProduct.push(item);
        }
      });
      this.mySwiper.destroy(true);
      this.swiperFn();
    },

    async getMainImageFn() {
      let res = await getMainImage();
      if (res.code === 1) this.mainImages = res.data;
    },

    goInquiry(page) {
      this.$router.push({
        path: "/inquiryView",
        query: {
          page,
        },
      });
    },
    // 前往产品详情
    goProductDetails(id) {
      this.$router.push({
        path: "/productDetails",
        query: {
          id,
        },
      });
    },

    // 轮播图挂在函数
    swiperFn() {
      this.$nextTick(() => {
        this.mySwiper = new swiper(".swiper-container", {
          direction: "horizontal", // 垂直切换选项
          loop: true, // 循环模式选项
          slidesPerView: 2,
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
          },

          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(./HomeView.scss);
</style>
