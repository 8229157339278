import serviceAxios from "../index";

// 获取面料列表
export const getMaterialList = (data) => {
  return serviceAxios({
    url: "/entity/listingMaterialGroup/search",
    method: "post",
    data,
  });
};
// 获取成衣列表带搜索
export const searchProductList = (data) => {
  return serviceAxios({
    url: "/entity/listingProduct/search",
    method: "post",
    data,
  });
};
